<!--
 * @Author: your name
 * @Date: 2021-11-11 14:58:25
 * @LastEditTime: 2021-12-08 15:32:37
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\team-building\list-display\listDialog.vue
-->
<template>
  <el-dialog
    width="970px"
    :title="headerText"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" :inline="true" label-width="150px" :model="paramsForm" :rules="rules" v-loading="dialogLoading">
      <el-row>
        <el-col :span="24">
          <el-form-item label="请选择所属项目部" prop="projectDepartment">
            <el-select
              v-model="paramsForm.projectDepartment"
              :disabled="isDisabled"
              placeholder="请选择"
              style="width:300px"
            >
              <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="预案名称" prop="name">
        <el-input
          v-model="paramsForm.name"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练地点" prop="location">
        <el-input
          v-model="paramsForm.location"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="组织部门" prop="sponsor">
        <el-input
          v-model="paramsForm.sponsor"
          maxlength="20"
          :disabled="isDisabled"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="总指挥" prop="commander">
        <el-input
          v-model="paramsForm.commander"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练时间" prop="rehearsalTime">
        <el-date-picker
          style="width:300px"
          v-model="paramsForm.rehearsalTime"
          type="date"
          :disabled="isDisabled"
          placeholder="请选择日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="实际时间" prop="realTime">
        <el-date-picker
          style="width:300px"
          v-model="paramsForm.realTime"
          type="date"
          :disabled="isDisabled"
          placeholder="请选择日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="参演部门和单位" prop="participants">
        <el-input
          v-model="paramsForm.participants"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练类别" prop="drillCategoryId">
        <el-select
          v-model="paramsForm.drillCategoryId"
          @change="statusChange"
          :disabled="isDisabled"
          placeholder="请选择"
          style="width:300px"
        >
          <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="实际演练部分" prop="drillContent">
        <el-input
          v-model="paramsForm.drillContent"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="物资准备和人员培训情况" prop="situationDescribe">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="paramsForm.situationDescribe"
          :disabled="isDisabled"
          maxlength="200"
          placeholder="请输入"
          style="width: 760px"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练过程描述" prop="processDescribe">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="paramsForm.processDescribe"
          maxlength="200"
          :disabled="isDisabled"
          placeholder="请输入"
          style="width: 760px"
        ></el-input>
      </el-form-item>
      <el-form-item label="预案适宜性" prop="planSuitableId">
        <el-select
          v-model="paramsForm.planSuitableId"
          @change="suitabilityChange"
          :disabled="isDisabled"
          placeholder="请选择"
          style="width:300px"
        >
          <el-option v-for="item in suitabilityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预案充分性" prop="planAdequacyId">
        <el-select
          v-model="paramsForm.planAdequacyId"
          @change="adequacyChange"
          :disabled="isDisabled"
          placeholder="请选择"
          style="width:300px"
        >
          <el-option v-for="item in adequacyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="人员到位情况" prop="personnelSituationId">
        <el-select
          v-model="paramsForm.personnelSituationId"
          :disabled="isDisabled"
          @change="userChange"
          placeholder="请选择"
          style="width:300px"
        >
          <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="现场物资到位情况" prop="materialSituationId">
        <el-select
          v-model="paramsForm.materialSituationId"
          :disabled="isDisabled"
          @change="liveChange"
          placeholder="请选择"
          style="width:300px"
        >
          <el-option v-for="item in liveList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="个人防护到位情况" prop="protectionSituationId">
        <el-select
          v-model="paramsForm.protectionSituationId"
          :disabled="isDisabled"
          placeholder="请选择"
          @change="personaChange"
          style="width:300px"
        >
          <el-option v-for="item in personalList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="整体组织情况" prop="organizationSituationId">
        <el-select
          v-model="paramsForm.organizationSituationId"
          :disabled="isDisabled"
          @change="organizationChange"
          placeholder="请选择"
          style="width:300px"
        >
          <el-option v-for="item in organizationList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="抢险组分工组织情况" prop="workDivisionId">
        <el-select
          v-model="paramsForm.workDivisionId"
          @change="rescueChange"
          :disabled="isDisabled"
          placeholder="请选择"
          style="width:300px"
        >
          <el-option v-for="item in rescueList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="实战效率评价" prop="efficiencyEvaluationId">
        <el-select
          v-model="paramsForm.efficiencyEvaluationId"
          @change="efficiencyChange"
          :disabled="isDisabled"
          placeholder="请选择"
          style="width:300px"
        >
          <el-option v-for="item in efficiencyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="报告上级" prop="reportId">
        <el-select
          v-model="paramsForm.reportId"
          @change="superiorChange"
          :disabled="isDisabled"
          placeholder="请选择"
          style="width:300px"
        >
          <el-option v-for="item in superiorList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="消防部门" prop="fireSituationId">
        <el-select
          v-model="paramsForm.fireSituationId"
          @change="fireDontroChange"
          :disabled="isDisabled"
          placeholder="请选择"
          style="width:300px"
        >
          <el-option v-for="item in fireDontrolList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="医疗救援部门" prop="medicalSituationId">
        <el-select
          v-model="paramsForm.medicalSituationId"
          :disabled="isDisabled"
          @change="medicineChange"
          placeholder="请选择"
          style="width:300px"
        >
          <el-option v-for="item in medicineList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="周边政府撤离配合" prop="evacuatelSituationId">
        <el-select
          v-model="paramsForm.evacuatelSituationId"
          :disabled="isDisabled"
          @change="governmentChange"
          placeholder="请选择"
          style="width:300px"
        >
          <el-option v-for="item in governmentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="存在问题和改进措施" prop="problemsMeasures">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="paramsForm.problemsMeasures"
          :disabled="isDisabled"
          maxlength="200"
          placeholder="请输入"
          style="width: 760px"
        ></el-input>
      </el-form-item>
      <el-form-item label="附件">
        <div v-if="headerText === '查看详情'">
          <div
            v-for="item in paramsForm.enclosureFileInfoList"
            :key="item.fileId"
            @click="uploadFile(item.fileId)"
            style="cursor:pointer"
          >
            {{ item.fileOriginName }}
          </div>
        </div>
        <upload-multiple-file
          v-else
          v-model="paramsForm.fileId"
          :default-list="defaultList"
          @get-file-data="getFileData"
        ></upload-multiple-file>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="deleteFunc" type="danger" v-if="isDisabled"
        >删 除</el-button
      >
      <el-button @click="openDetail" type="primary" v-if="isDisabled"
        >修 改</el-button
      >
      <el-button type="primary" v-if="!isDisabled" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import downloadFileFunc from '@/utils/download-file.js';
import { downloadFile } from '@/api/general-user/internal-data.js';
import {
  postEmergencyDrills,
  getEmergencyDetail,
  getEmergencyEdit,
  getEmergencyDelete
} from '@/api/general-user/emergency-rescue.js';
import UploadMultipleFile from 'components/Upload/UploadMultipleFile';
export default {
  components: {
    UploadMultipleFile
  },
  props: {
    headerText: {
      type: String
    },
    projectList: {
      type: Array
    },
    detailList: {
      type: Array
    }
  },
  data() {
    return {
      paramsForm: {
        projectDepartment: '',
        name: '',
        sponsor: '',
        commander: '',
        participants: '',
        rehearsalTime: '',
        location: '',
        situationDescribe: '',
        processDescribe: '',
        realTime: '',
        problemsMeasures: '',
        drillCategoryId: '',
        planAdequacyId: '',
        personnelSituationId: '',
        materialSituationId: '',
        protectionSituationId: '',
        organizationSituationId: '',
        workDivisionId: '',
        efficiencyEvaluationId: '',
        reportId: '',
        fireSituationId: '',
        medicalSituationId: '',
        evacuatelSituationId: '',
        planSuitableId: '',
        drillContent: ''
      },
      statusList: [],
      visible: false,
      rules: {
        projectDepartment: [{ required: true, message: '请选择所属项目部', trigger: 'change' }],
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        sponsor: [{ required: true, message: '请输入', trigger: 'change' }],
        commander: [{ required: true, message: '请输入', trigger: 'change' }],
        participants: [{ required: true, message: '请输入', trigger: 'change' }],
        location: [{ required: true, message: '请输入', trigger: 'change' }],
        situationDescribe: [{ required: true, message: '请输入', trigger: 'change' }],
        processDescribe: [{ required: true, message: '请输入', trigger: 'change' }],
        drillContent: [{ required: true, message: '请输入', trigger: 'change' }],
        problemsMeasures: [{ required: true, message: '请输入', trigger: 'change' }],
        rehearsalTime: [{ required: true, message: '请选择日期', trigger: 'change' }],
        realTime: [{ required: true, message: '请选择日期', trigger: 'change' }],
        drillCategoryId: [{ required: true, message: '请选择', trigger: 'change' }],
        planAdequacyId: [{ required: true, message: '请选择', trigger: 'change' }],
        personnelSituationId: [{ required: true, message: '请选择', trigger: 'change' }],
        materialSituationId: [{ required: true, message: '请选择', trigger: 'change' }],
        protectionSituationId: [{ required: true, message: '请选择', trigger: 'change' }],
        organizationSituationId: [{ required: true, message: '请选择', trigger: 'change' }],
        workDivisionId: [{ required: true, message: '请选择', trigger: 'change' }],
        efficiencyEvaluationId: [{ required: true, message: '请选择', trigger: 'change' }],
        reportId: [{ required: true, message: '请选择', trigger: 'change' }],
        fireSituationId: [{ required: true, message: '请选择', trigger: 'change' }],
        medicalSituationId: [{ required: true, message: '请选择', trigger: 'change' }],
        evacuatelSituationId: [{ required: true, message: '请选择', trigger: 'change' }],
        planSuitableId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      detailId: '',
      isDisabled: false,
      dialogLoading: false,
      confirmLoading: false,
      pickerOptions: {
        //选择今天以及之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      defaultList: [],
      suitabilityList: [],
      adequacyList: [],
      userList: [],
      liveList: [],
      personalList: [],
      organizationList: [],
      rescueList: [],
      efficiencyList: [],
      superiorList: [],
      fireDontrolList: [],
      medicineList: [],
      governmentList: [],
      paramsFormCopy: {}
    };
  },
  methods: {
    //下载附件
    uploadFile(fileId) {
      downloadFile({ fileId }).then(res => {
        downloadFileFunc(res);
      });
    },
    handleColse() {
      this.visible = false;
      this.$refs.form.resetFields();
      this.detailId = '';
      this.paramsForm = this.$options.data().paramsForm;
      this.paramsFormCopy = {};
      this.defaultList = [];
    },
    open(row) {
      this.visible = true;
      if (row?.id) {
        this.getEmergencyDetail(row.id);
        for (const key in this.rules) {
          this.rules[key][0].required = false;
        }
        this.isDisabled = true;
      } else {
        for (const key in this.rules) {
          this.rules[key][0].required = true;
        }
        this.isDisabled = false;
      }
      // this.$nextTick(() => {
      //   this.paramsForm.orgId = orgId;
      //   this.paramsForm.orgName = orgName;
      //   // this.getGrantUserListData(orgId);
      // });
    },
    getFileData(fileIdList) {
      this.paramsForm.enclosureIds = fileIdList.join(',');
    },
    // 获取详情
    getEmergencyDetail(id) {
      this.dialogLoading = true;
      getEmergencyDetail({ id })
        .then(res => {
          if (!res.success) {
            this.$message.error(res.message);
            return;
          }
          this.paramsFormCopy = { ...res.data };
          this.paramsForm = res.data;
          // 因为这些下拉框的数据都是配置的 如果选择删除之前的数据 也要显示数据
          // 所以详情进来先赋值name
          this.paramsForm.drillCategoryId = res.data.drillCategoryName;
          this.paramsForm.planSuitableId = res.data.planSuitableName;
          this.paramsForm.planAdequacyId = res.data.planAdequacyName;
          this.paramsForm.personnelSituationId = res.data.personnelSituationName;
          this.paramsForm.materialSituationId = res.data.materialSituationName;
          this.paramsForm.protectionSituationId = res.data.protectionSituationName;
          this.paramsForm.organizationSituationId = res.data.organizationSituationName;
          this.paramsForm.workDivisionId = res.data.workDivisionName;
          this.paramsForm.efficiencyEvaluationId = res.data.efficiencyEvaluationName;
          this.paramsForm.reportId = res.data.reportName;
          this.paramsForm.fireSituationId = res.data.fireSituationName;
          this.paramsForm.medicalSituationId = res.data.medicalSituationName;
          this.paramsForm.evacuatelSituationId = res.data.evacuatelSituationName;
          this.detailId = res.data.id;
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },
    deleteFunc() {
      this.$confirm('请确认是否删除该演练记录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deletePlan();
      });
    },
    //删除接口
    deletePlan() {
      getEmergencyDelete({ id: this.detailId }).then(res => {
          this.$message.success('删除成功');
          this.handleColse();
          this.$emit('on-success');
      });
    },
    openDetail() {
      if (this.paramsForm.enclosureFileInfoList && this.paramsForm.enclosureFileInfoList.length) {
        this.paramsForm.enclosureFileInfoList.forEach(item => {
          item.name = item.fileOriginName;
          item.url = item.filePath;
        });
        this.defaultList = this.paramsForm.enclosureFileInfoList; //附件回显
      }
      this.dialogLoading = true;
      this.isDisabled = false;
      this.$emit('update:headerText', '修改计划');
      for (const key in this.rules) {
        this.rules[key][0].required = false;
      }
      this.statusList.find(item => {
        if (item.id === this.paramsFormCopy.drillCategoryId) {
          return (this.paramsForm.drillCategoryId = item.id);
        } else {
          return (this.paramsForm.drillCategoryId = '');
        }
      });
      this.suitabilityList.find(item => {
        if (item.id === this.paramsFormCopy.planSuitableId) {
          return (this.paramsForm.planSuitableId = item.id);
        } else {
          return (this.paramsForm.planSuitableId = '');
        }
      });
      this.adequacyList.find(item => {
        if (item.id === this.paramsFormCopy.planAdequacyId) {
          return (this.paramsForm.planAdequacyId = item.id);
        } else {
          return (this.paramsForm.planAdequacyId = '');
        }
      });
      this.userList.find(item => {
        if (item.id === this.paramsFormCopy.personnelSituationId) {
          return (this.paramsForm.personnelSituationId = item.id);
        } else {
          return (this.paramsForm.personnelSituationId = '');
        }
      });
      this.liveList.find(item => {
        if (item.id === this.paramsFormCopy.materialSituationId) {
          return (this.paramsForm.materialSituationId = item.id);
        } else {
          return (this.paramsForm.materialSituationId = '');
        }
      });
      this.personalList.find(item => {
        if (item.id === this.paramsFormCopy.protectionSituationId) {
          return (this.paramsForm.protectionSituationId = item.id);
        } else {
          return (this.paramsForm.protectionSituationId = '');
        }
      });
      this.organizationList.find(item => {
        if (item.id === this.paramsFormCopy.organizationSituationId) {
          return (this.paramsForm.organizationSituationId = item.id);
        } else {
          return (this.paramsForm.organizationSituationId = '');
        }
      });
      this.rescueList.find(item => {
        if (item.id === this.paramsFormCopy.workDivisionId) {
          return (this.paramsForm.workDivisionId = item.id);
        } else {
          return (this.paramsForm.workDivisionId = '');
        }
      });
      this.efficiencyList.find(item => {
        if (item.id === this.paramsFormCopy.efficiencyEvaluationId) {
          return (this.paramsForm.efficiencyEvaluationId = item.id);
        } else {
          return (this.paramsForm.efficiencyEvaluationId = '');
        }
      });
      this.superiorList.find(item => {
        if (item.id === this.paramsFormCopy.reportId) {
          return (this.paramsForm.reportId = item.id);
        } else {
          return (this.paramsForm.reportId = '');
        }
      });
      this.fireDontrolList.find(item => {
        if (item.id === this.paramsFormCopy.fireSituationId) {
          return (this.paramsForm.fireSituationId = item.id);
        } else {
          return (this.paramsForm.fireSituationId = '');
        }
      });
      this.medicineList.find(item => {
        if (item.id === this.paramsFormCopy.medicalSituationId) {
          return (this.paramsForm.medicalSituationId = item.id);
        } else {
          return (this.paramsForm.medicalSituationId = '');
        }
      });
      this.governmentList.find(item => {
        if (item.id === this.paramsFormCopy.evacuatelSituationId) {
          return (this.paramsForm.evacuatelSituationId = item.id);
        } else {
          return (this.paramsForm.evacuatelSituationId = '');
        }
      });
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        this.confirmLoading = true;
        if (!this.detailId) {
          postEmergencyDrills(this.paramsForm)
            .then(res => {
              this.$message.success('新增成功！');
              this.handleColse();
              this.$emit('on-success');
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        } else {
          getEmergencyEdit(this.paramsForm)
            .then(res => {
              this.$message.success('修改成功！');
              this.handleColse();
              this.$emit('on-success');
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    statusChange(id) {
      this.statusList.find(item => {
        if (item.id === id) {
          this.paramsForm.drillCategoryName = item.name;
        }
      });
    },
    suitabilityChange(id) {
      this.suitabilityList.find(item => {
        if (item.id === id) {
          this.paramsForm.planSuitableName = item.name;
        }
      });
    },
    adequacyChange(id) {
      this.adequacyList.find(item => {
        if (item.id === id) {
          this.paramsForm.planAdequacyName = item.name;
        }
      });
    },
    userChange(id) {
      this.userList.find(item => {
        if (item.id === id) {
          this.paramsForm.personnelSituationName = item.name;
        }
      });
    },
    liveChange(id) {
      this.liveList.find(item => {
        if (item.id === id) {
          this.paramsForm.materialSituationName = item.name;
        }
      });
    },
    personaChange(id) {
      this.personalList.find(item => {
        if (item.id === id) {
          this.paramsForm.protectionSituationName = item.name;
        }
      });
    },
    organizationChange(id) {
      this.organizationList.find(item => {
        if (item.id === id) {
          this.paramsForm.organizationSituationName = item.name;
        }
      });
    },
    rescueChange(id) {
      this.rescueList.find(item => {
        if (item.id === id) {
          this.paramsForm.workDivisionName = item.name;
        }
      });
    },
    efficiencyChange(id) {
      this.efficiencyList.find(item => {
        if (item.id === id) {
          this.paramsForm.efficiencyEvaluationName = item.name;
        }
      });
    },
    superiorChange(id) {
      this.superiorList.find(item => {
        if (item.id === id) {
          this.paramsForm.reportName = item.name;
        }
      });
    },
    fireDontroChange(id) {
      this.fireDontrolList.find(item => {
        if (item.id === id) {
          this.paramsForm.fireSituationName = item.name;
        }
      });
    },
    medicineChange(id) {
      this.medicineList.find(item => {
        if (item.id === id) {
          this.paramsForm.medicalSituationName = item.name;
        }
      });
    },
    governmentChange(id) {
      this.governmentList.find(item => {
        if (item.id === id) {
          this.paramsForm.evacuatelSituationName = item.name;
        }
      });
    }
  },
  watch: {
    detailList: {
      handler: function(val) {
        val.forEach(item => {
          if (item.code === 'four_suitable') {
            this.suitabilityList = item.downList;
          } else if (item.code === 'four_ample') {
            this.adequacyList = item.downList;
          } else if (item.code === 'four_category') {
            this.statusList = item.downList;
          } else if (item.code === 'four_persennel') {
            this.userList = item.downList;
          } else if (item.code === 'four_material') {
            this.liveList = item.downList;
          } else if (item.code === 'four_protect') {
            this.personalList = item.downList;
          } else if (item.code === 'four_organization') {
            this.organizationList = item.downList;
          } else if (item.code === 'four_division') {
            this.rescueList = item.downList;
          } else if (item.code === 'four_evaluation') {
            this.efficiencyList = item.downList;
          } else if (item.code === 'four_report') {
            this.superiorList = item.downList;
          } else if (item.code === 'four_fire') {
            this.fireDontrolList = item.downList;
          } else if (item.code === 'four_medical') {
            this.medicineList = item.downList;
          } else if (item.code === 'four_evacuatel') {
            this.governmentList = item.downList;
          }
        });
      },
      deep: true
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.el-date-picker .el-picker-panel__content {
  height: auto;
}
</style>
